<div class="container box-usp">
  <div class="item">
    <img src="assets/images/icons/usp-2.svg" alt="{{ 'usp_title_1' | translate }}" />
    <div class="title-usp">{{ 'usp_title_1' | translate }}</div>
    <div class="desc">{{ 'usp_description_1' | translate }}</div>
  </div>

  <div class="item short">
    <img src="assets/images/icons/usp-3.svg" alt="{{ 'usp_title_2' | translate }}" />
    <div class="title-usp">{{ 'usp_title_2' | translate }}</div>
    <div class="desc">{{ 'usp_description_2' | translate }}</div>
  </div>

  <div class="item">
    <img src="assets/images/icons/usp-1.svg" alt="{{ 'usp_title_3' | translate }}" />
    <div class="title-usp">{{ 'usp_title_3' | translate }}</div>
    <div class="desc">{{ 'usp_description_3' | translate }}</div>
  </div>
</div>
