<div
  class="items"
  [ngClass]="[
    weLoveTemplateRef ? 'we-love' : '',
    campaignsTemplateRef ? 'showcase-campaign' : '',
    recommendTemplateRef ? 'recommend' : ''
  ]"
>
  <div class="item-box" *ngFor="let item of data?.items">
    <!-- <ng-container
      *ngIf="weLoveTemplateRef"
      [ngTemplateOutlet]="weLoveTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container> -->

    <ng-container
      *ngIf="campaignsTemplateRef"
      [ngTemplateOutlet]="campaignsTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>

    <ng-container
      *ngIf="recommendTemplateRef"
      [ngTemplateOutlet]="recommendTemplateRef"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </div>
</div>
