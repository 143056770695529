<div class="tvb-checkbox">
  <div class="box-input">
    <input
      type="checkbox"
      [checked]="isChecked"
      (change)="onChanged($event)"
      (blur)="onBlur($event)"
      [disabled]="disabled"
      name="{{ name }}"
      id="{{ id }}"
      [classList]="isRounded ? 'checkbox-rounded' : ''"
    />
  </div>
  <div [ngClass]="isRounded ? 'checkbox-rounded-label' : ''" class="box-label">
    <label for="{{ id }}">
      <small>
        <ng-content></ng-content>
      </small>
    </label>
  </div>
</div>
