export const environment = {
  production: false,
  name: 'dev',
  version: 'dev-51',
  HOST_URL: 'https://dev.thevintagebardev.com',
  MOBILE_API_URL: 'https://common-api.dev.thevintagebardev.com/services/mobile/api/v1',
  ADMIN_API_URL: 'https://common-api.dev.thevintagebardev.com/services/admin/api/v1',
  API_URL: 'https://common-api.dev.thevintagebardev.com/services/api/v1',
  API_V2_URL: 'https://common-api.dev.thevintagebardev.com/apiv2/api/',
  OFFER_API_URL: 'https://offers.dev.thevintagebardev.com/api/v1',
  PRIVATE_SELLER_ONBOARDING_API_URL: 'https://common-api.dev.thevintagebardev.com/services/pso/api/v1',
  GOOGLE_CLIENT_ID: '757024001457-oskmea2tgld58pr0891nvfhuumgh2cdk.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '3025845434364740',
  VERSION: '1.0.3',
  baseUrl: '/l/',
  baseAngularUrl: '/',
  PRODUCT_IMGIX_URL: 'https://tvbdevimages.azureedge.net/',
  UPLOADS_IMGIX_URL: 'https://tvbdevimages.azureedge.net/',
  demainUrl: 'dev.thevintagebardev.com',
  cookieDomain: '.dev.thevintagebardev.com',
  baseUrlImage: 'https://common-api.dev.thevintagebardev.com/services/',
  baseRemoteUrl: 'https://dev.thevintagebardev.com/',
  INSTANT_SEARCH_APP_ID: 'IM0JT4Q24L',
  INSTANT_SEARCH_INDEX_NAME: 'ec2_STORE',
  INSTANT_SEARCH_INDEX_NAME_PERSONAL: 'ec2_STORE_personal',
  INSTANT_SEARCH_INDEX_NAME_PRICE_DESC: 'ec2_STORE_price_desc',
  INSTANT_SEARCH_INDEX_NAME_PRICE_ASC: 'ec2_STORE_price_asc',
  INSTANT_SEARCH_INDEX_NAME_MOST_WANTED: 'ec2_STORE_most_wanted',
  INSTANT_SEARCH_INDEX_NAME_QUERY_SUGGESTIONS: 'ec2_STORE_query_suggestions',
  INSTANT_SEARCH_INDEX_NAME_DESIGNER: 'ec2_designers',
  INSTANT_SEARCH_SEARCH_API_KEY: '7daf432a3408f3d20e342d4e28326127',
  invoicePDFurl: 'https://dev-thevintagebar-uploads.imgix.net/invoice_pdf/',
  USER_SERVICE_URL: 'https://dev.thevintagebardev.com/user-service',
  USER_API_URL: 'https://common-api.dev.thevintagebardev.com/services/user/api/v1',
  API_CATALOG_URL: 'https://dev.thevintagebardev.com/catalog-service/',
  PRODUCT_IMAGE_URL: 'https://common-api.dev.thevintagebardev.com/services/uploads/product/',
  TIKTOK_ID: 'C7P6CSI40CK6SQS6AIF0',
  /**
   *  Catalog Service KeyCloak Token Configs
   */

  API_CATALOG_SVC: 'https://dev.thevintagebardev.com/catalog-service/public',
  KEY_CLOAK_URL: 'http://20.127.38.138:8080/auth/realms/tvb-dev/protocol/openid-connect/token',
  KEY_CLOAK_PAYLOAD: {
    client_id: process.env.ClientID,
    grant_type: process.env.GrantType,
    client_secret: process.env.ClientSecret,
  },
  RECAPTCHA_SITE_KEY: '6Ld4fJApAAAAAALcN0Jssul-N0fUUYmf860Btu_4',
  IMGIX_UPLOADS_URL: 'https://tvbdevimages.azureedge.net/',
  PRODUCT_IMAGE_UPLOAD_SIZE: 10, // MB
  AWIN_FLAG: 0,
  AWIN_MERCHANT_ID: {
    DK: '29233',
    SE: '29231',
    DE: '29229',
  },

  Finance_Base_Url: 'https://dev.thevintagebardev.com/finance-service/',
  SEARCH_API_URL: 'https://elk.dev.thevintagebardev.com/api',
  THIRD_PARTY_API_URL: 'https://thirdparty.dev.thevintagebardev.com/api',
  ORDERS_API_URL: 'https://ordersapi.dev.thevintagebardev.com/api',
  SHOW_WEBSHIPPER_SERVICES: false,
  USE_ELK: true,
  ADYEN_CLIENT_KEY: 'test_HUGYLP32MZF5BEYFZQFEYKXHFQYPC75F',
  GOOGLE_OPTIMIZE_ID: 'OPT-WH5KTSF',
  EXCLUDE_URL_SUFFIX_FROM_JWT: [
    'client/pdp',
    'public/refresh',
    'orderItem/savePortalReturn',
    'orderItem/portalReturnLabel',
  ],
  PUBLIC_REQUESTS_WILDCARD: [/client\/pdp/g],
  SHOW_SELL_AN_ITEM: false,
};
