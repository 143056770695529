import { Component, Input, OnInit } from '@angular/core';
import { MemberBio } from '@schemas/community/member-bio';
import { Seller } from '@schemas/seller.interface';
import { MemberBioService } from '@services/community/member-bio.service';
import { ProductService } from '@services/product.service';
import { UserService } from '@services/user.service';
import { UserRolesEnum } from '@shared/enums/user-roles.enum';

@Component({
  selector: 'tvb-seller-information',
  templateUrl: './tvb-seller-information.component.html',
  styleUrls: ['./tvb-seller-information.component.scss'],
})
export class TvbSellerInformationComponent implements OnInit {
  @Input() userId: number;
  @Input() product: any;
  TVB_USER_ID = 0;
  listedItems: number;
  soldItems: number;
  seller: Seller = null;
  tvbUser = false;
  displayedName: string;

  constructor(
    private productService: ProductService,
    private userService: UserService,
    private memberBioService: MemberBioService
  ) {}

  ngOnInit(): void {
    this.getSellerProductCount();
    this.getSellerSoldCount();
    this.getSellerInfo();
    this.product.commission_user_type =
      this.product.commission_user_id == 0 ? 'Professional' : this.product.commission_user_type;
  }

  getSellerProductCount() {
    this.productService.getSellerInfo(this.userId).subscribe(data => {
      this.listedItems = data;
    });
  }

  getSellerSoldCount() {
    this.productService.getSellerSoldItems(this.userId).subscribe(data => {
      this.soldItems = data;
    });
  }

  getSellerInfo() {
    if (this.userId == this.TVB_USER_ID) {
      this.tvbUser = true;
      this.seller = {
        id: 0,
        company_name: 'The Vintage Bar',
        first_name: null,
        last_name: null,
        country: 'Denmark',
        image_id: 'assets/images/logo.jpg',
        username: 'the-vintage-bar',
        role_id: 15,
      };
      this.setDisplayedName(this.seller);
    }
    else {
      this.userService.getSeller(this.userId).subscribe(data => {
        if (data) {
          this.seller = data['user'];
          this.memberBioService.getBioInfo(data['user']['username']).subscribe(data => {
            this.setDisplayedName(data);
          });
        }
      });
    }
  }

  private setDisplayedName(member: MemberBio) {
    this.displayedName = member.displayed_name ?? member.company_name ?? member.username;
  }
}
