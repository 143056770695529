import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { APP_CONSTANTS } from '@shared/constants/app-constants';
import { LocalStorageService } from './local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { Currency, Language } from '@schemas/app.interface';
import { SellerSoldItemsResponseModel } from '@shared/models/seller-sold-items-response.model';
import { AcceptTermsModel } from '@shared/models/accept-terms.interface';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cookieService: CookieService
  ) {}

  public getSoldItems(params: any = {}): Observable<SellerSoldItemsResponseModel> {
    params = {
      sortColumn: 'created_at',
      sortOrder: 'desc',
      pageSize: '10',
      ...params,
    };

    let queryParams = new HttpParams();

    Object.keys(params).forEach(key => {
      queryParams = queryParams.append(key, params[key]);
    });

    const options = {
      params: queryParams,
    };

    return this.http.get<SellerSoldItemsResponseModel>(`${environment.API_V2_URL}orderItem/getSellerSoldItem`, options);
  }

  public exportSoldItems(request: any): Observable<any> {
    return this.http.get(`${environment.API_V2_URL}orderItem/exportSellerSoldItemsCsv`, {
      responseType: 'blob',
      observe: 'response',
      params: request,
    });
  }

  getlanguageList(): Observable<{ list: Language[] }> {
    return this.http.get<{ list: Language[] }>(environment.API_V2_URL + 'language/getLanguages');
  }

  getCurrencyList(): Observable<Currency[]> {
    return this.http.get<Currency[]>(environment.API_V2_URL + 'currency/active');
  }

  getCountries(region = ''): Observable<any> {
    let searchParams;

    if (region) {
      searchParams = { region };
    }

    return this.http.get(`${environment.API_V2_URL}/countriesmapping`, { params: searchParams });
  }

  getCountriesReal(searchParams: any = {}): Observable<any> {
    return this.http.get(`${environment.API_V2_URL}countries`, { params: searchParams });
  }

  acceptTerms(request: AcceptTermsModel): Observable<boolean> {
    return this.http.post<boolean>(`${environment.API_V2_URL}account/accept-terms`, request);
  }

  getAcceptTerms(user_id, term_id): Observable<any> {
    return this.http.get(`${environment.API_V2_URL}account/accept-terms/${user_id}/${term_id}`);
  }

  updateCoFundedInfo(coFundedPercentage: number, itemDiscount: number): Observable<any> {
    return this.http.put(`${environment.API_V2_URL}account/co-funded`, {
      co_funded: coFundedPercentage,
      item_discount: itemDiscount,
    });
  }

  createWarehouseAdhoc(data: { message: any }) {
    return this.http.post<boolean>(`${environment.API_V2_URL}account/create-warehouse-adhoc`, data);
  }
}
