import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'currencyCode',
})
export class CodeCurrencyPipe implements PipeTransform {
  transform(val: number, currencyCode = 'EUR') {
    const currencyAmount = new Intl.NumberFormat(getLocale(currencyCode), {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
    })
      .format(Number(val))
      .replace('£', '')
      .replace('$', '')
      .replace('SEK', '')
      .replace('DKK', '')
      .replace('€', '');

    return `${currencyAmount} ${currencyCode}`;
  }
}
function getLocale(currencyCode) {
  switch (currencyCode) {
    case 'EUR':
      return 'sfb';
    case 'USD':
      return 'en-US';
    case 'GBP':
      return 'en-US';
    case 'DKK':
    case 'NOK':
      return 'en-US';
    case 'SEK':
      return 'en-US';
    default:
      return 'en-US';
  }
}
