<div class="header checkoutheader">
  <div class="container">
    <div class="row">
      <div class="logo-container col-lg-6 col-md-6 col-xs-6">
        <div class="checkoutlogo">
          <a href="#"><img src="assets/images/logo.jpg" class="checkoutlogoimage" /></a>
        </div>
      </div>
      <div class="secure-selling col-lg-6 col-md-6 col-xs-6">
        <div class="checkoutright">
          <p class="checkoutrightp">
            <span class="checkoutrightspan">Secure Login</span>
            <img src="assets/images/security.svg" class="checkoutrightimg" />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
