<ng-container *ngFor="let topic of topics">
  <div class="accordion-item" [ngClass]="{ active: topic.active }">
    <div class="accordion-header" (click)="expandTopicRow(topic)">
      <div class="topic-title" [style]="headingStyles.length > 0 ? headingStyles : ''">
        {{ topic.title | translate }}
      </div>

      <span class="accordion-arrow" [style]="arrowStyles.length > 0 ? arrowStyles : ''">
        <img class="expand-arrow" src="assets/images/icons/help_and_support/arrow.svg" />
      </span>
    </div>

    <div class="topic-text">
      <ng-container [ngComponentOutlet]="topic.component"></ng-container>
    </div>
  </div>
</ng-container>
