<div class="tvb-dialog-container">
  <i class="material-icons" (click)="onClose(false)">
    <img src="/assets/images/cancel.svg" aria-hidden="true" />
  </i>
  <div class="tvb-dialog-body">
    <div class="tvb-dialog-inner-body">
      <div class="title-info">
        <h3>{{ 'Product Conditions' | translate }}</h3>
        <div class="box-content">
          <h4>{{ 'NEW_WITH_TAGS' | translate }}</h4>
          <div class="content">
            {{ 'NEW_WITH_TAGS_DESC' | translate }}
          </div>
        </div>
        <div class="box-content">
          <h4>{{ 'GOOD_BUT_USED' | translate }}</h4>
          <div class="content">
            {{ 'GOOD_BUT_USED_DESC' | translate }}
          </div>
        </div>
        <div class="box-content">
          <h4>{{ 'EXCELLENT' | translate }}</h4>
          <div class="content">
            {{ 'EXCELLENT_DESC' | translate }}
          </div>
        </div>
        <div class="box-content">
          <h4>{{ 'WORN_WITH_LOVE' | translate }}</h4>
          <div class="content">
            {{ 'WORN_WITH_LOVE_DESC' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
