import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-authentication-services-dialog',
  templateUrl: './authentication-services-dialog.component.html',
  styleUrls: ['./authentication-services-dialog.component.scss'],
})
export class AuthenticationServicesDialogComponent implements OnInit {
  public title = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AuthenticationServicesDialogComponent,
    private mdDialogRef: MatDialogRef<AuthenticationServicesDialogComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public cancel(): void {
    this.close(false);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.close(false);
  }
}
