import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tvb-unique-selling-point',
  templateUrl: './unique-selling-point.component.html',
  styleUrls: ['./unique-selling-point.component.scss'],
})
export class UniqueSellingPointComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
