<div *ngIf="(authService.isLoggedInObs | async) && (user$ | async)?.role_id != 2; else elseBlock">
  <div class="wishlist-icon">
    <span *ngIf="addWishIcon">
      <button class="btn-link-reset" (click)="addToWishList(productId)" [attr.id]="'add' + productId">
        <i class="fa fa-heart-o" aria-hidden="true"></i>
      </button>
    </span>
    <span *ngIf="!addWishIcon">
      <a href="javascript:void(0);" (click)="removeWishList(productId)" [attr.id]="'remove' + productId">
        <i class="fa fa-heart" aria-hidden="true"></i>
      </a>
    </span>
  </div>
</div>
<ng-template #elseBlock>
  <div class="wishlist-icon">
    <span>
      <button class="btn-link-reset" (click)="openPopup(true, productId)">
        <i class="fa fa-heart-o" aria-hidden="true"></i>
      </button>
    </span>
  </div>
</ng-template>
<span class="wishlistCount">{{ total_wishlisted }}</span>
