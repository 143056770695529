import { CheckoutActionTypes } from '../actions/checkout.actions';

export const initialState = {
  path: 'adyen-address-step',
  shippingAddress: null,
  paymentMethod: null,
  order: null,
};

export function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case CheckoutActionTypes.addOrder: {
      const newState = {
        ...state,
        order: { ...action.order },
        path: action.path ?? state.path,
      };

      return newState;
    }
    case CheckoutActionTypes.addPaymentMethod: {
      const newState = {
        ...state,
        paymentMethod: { ...action.paymentMethod },
        path: action.path ?? state.path,
      };

      return newState;
    }
    case CheckoutActionTypes.addShippingAddress: {
      const newState = action.billingAddress
        ? {
          ...state,
          shippingAddress: { ...action.shippingAddress },
          billingAddress: { ...action.billingAddress },
          path: action.path ?? state.path,
        }
        : { ...state, shippingAddress: { ...action.shippingAddress } };

      return newState;
    }
    case CheckoutActionTypes.removeShippingAddress: {
      const newState = {
        ...state,
        shippingAddress: null,
        billingAddress: null,
        path: action.path ?? state.path,
      };

      return newState;
    }
    default:
      return state;
  }
}
