<div class="ShippingBox">
  <div class="item-box">
    <p class="title">Delivery</p>
    <div class="item-description">
      <ng-container *ngIf="tvbSeller">
        <p class="paragraph">
          You can expect a delivery time of 2-5 business days within Europe and 4-8 business days
          for the rest of the world.
        </p>
        <p class="paragraph">
          When the item is shipped from our Copenhagen office you will receive a track and trace
          link via email.
        </p>
        <p class="paragraph">
          You can also follow your shipment by clicking on the item under ‘My Orders’.
        </p>
      </ng-container>
      <ng-container *ngIf="professionalSeller">
        <p class="paragraph">
          We encourage all sellers to ship their sold items as soon as possible as to ensure the
          quickest possible delivery times.
        </p>
        <p class="paragraph">
          You can expect a delivery time of 6-18 business days within Europe and 8-21 business days
          for the rest of the world.
        </p>
        <p class="paragraph">
          Once the product has been approved in the quality control process and is ready to be sent,
          you will receive a track and trace link via email.
        </p>
        <p class="paragraph">
          You can also follow your shipment by clicking on the item under ‘My Orders’.
        </p>
        <p class="paragraph">
          If the seller hasn’t shipped the item after 10 days, we will cancel the order and you will
          automatically receive a full refund.
        </p>
      </ng-container>
      <ng-container *ngIf="privateSeller">
        <p class="paragraph">
          We encourage all sellers to ship their sold items as soon as possible as to ensure the
          quickest possible delivery times.
        </p>
        <p class="paragraph">
          You can expect a delivery time of 6-18 business days within Europe and 8-21 business days
          for the rest of the world.
        </p>
        <p class="paragraph">
          Once the product has been approved in the quality control process and is ready to be sent,
          you will receive a track and trace link via email.
        </p>
        <p class="paragraph">
          You can also follow your shipment by clicking on the item under ‘My Orders’.
        </p>
        <p class="paragraph">
          If the seller hasn’t shipped the item after 10 days, we will cancel the order and you will
          automatically receive a full refund.
        </p>
      </ng-container>
    </div>
  </div>

  <div class="item-box">
    <p class="title">Return</p>
    <div class="item-description">
      <ng-container *ngIf="tvbSeller">
        <p class="paragraph">
          Because this item is listed by a professional seller, you have two options in case you
          turn out not to be happy with it.
        </p>
        <p class="paragraph">
          Re-list the item within 72 hours of receiving it and you won’t be charged a commission
          fee. The item will be listed at the same price you purchased it for and only a handling
          fee will be deducted.
        </p>
         
        <p class="paragraph">Return it unused and undamaged within 14 days of receiving it.</p>
      </ng-container>
      <ng-container *ngIf="professionalSeller">
        <p class="paragraph">
          Because this item is listed by a professional seller, you have two options in case you
          turn out not to be happy with it.
        </p>
        <p class="paragraph">
          Re-list the item within 72 hours of receiving it and you won’t be charged a commission
          fee. The item will be listed at the same price you purchased it for and only a handling
          fee will be deducted.
        </p>
        <p class="paragraph">Return it unused and undamaged within 14 days of receiving it.</p>
      </ng-container>
      <ng-container *ngIf="privateSeller">
        <p class="paragraph">
          If you turn out to not be happy with your purchase, you will have the option to re-list it
          within 72 hours of receiving it and you won’t be charged a commission fee.
        </p>
        <p class="paragraph">
          The item will be listed at the same price you purchased it for and only a handling fee
          will be deducted.
        </p>
      </ng-container>
    </div>
  </div>

  <div class="item-box">
    <p class="title">DHL Express</p>
    <div class="item-description">
      <ng-container *ngIf="tvbSeller">
        <p class="paragraph">Your order will be shipped with DHL Express.</p>
        <p class="paragraph">
          We have chosen to partner up with this courier as they – through their On Demand option –
          offer more flexible delivery options, where you will be contacted via email or text
          regarding delivery options best suited for you.
        </p>
        <p class="paragraph">
          Moreover DHL Express is an emission friendly choice, as we take part in their GoGreen
          program, which means they reduce the carbon footprint of your purchase.
        </p>
      </ng-container>
      <ng-container *ngIf="professionalSeller">
        <p class="paragraph">Your order will be shipped with DHL Express.</p>
        <p class="paragraph">
          We have chosen to partner up with this courier as they – through their On Demand option –
          offer more flexible delivery options, where you will be contacted via email or text
          regarding delivery options best suited for you.
        </p>
        <p class="paragraph">
          Moreover DHL Express is an emission friendly choice, as we take part in their GoGreen
          program, which means they reduce the carbon footprint of your purchase.
        </p>
      </ng-container>
      <ng-container *ngIf="privateSeller">
        <p class="paragraph">Your order will be shipped with DHL Express.</p>
        <p class="paragraph">
          We have chosen to partner up with this courier as they – through their On Demand option –
          offer more flexible delivery options, where you will be contacted via email or text
          regarding delivery options best suited for you.
        </p>
        <p class="paragraph">
          Moreover DHL Express is an emission friendly choice, as we take part in their GoGreen
          program, which means they reduce the carbon footprint of your purchase.
        </p>
      </ng-container>
    </div>
  </div>
</div>
