<div class="ps-table">
  <h2>{{ 'Size Converter' | translate }}</h2>
  <i class="material-icons" (click)="onClose(false)">clear</i>
  <p>{{ 'The following charts will help you match the size for this product to your usual size.' | translate }}</p>
  <div class="table-body table-bordered">
    <table class="table">
      <thead>
        <tr>
          <th>S/M/L</th>
          <th>XXL</th>
          <th>XXS</th>
          <th>XSL</th>
          <th>S</th>
          <th>M</th>
          <th>L</th>
          <th>XL</th>
          <th>XXL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="grey-class">UK</th>
          <td>2</td>
          <td>4</td>
          <td>6</td>
          <td>8</td>
          <td>10</td>
          <td>12</td>
          <td>14</td>
          <td>16</td>
        </tr>
        <tr>
          <th class="grey-class">US</th>
          <td>00</td>
          <td>0</td>
          <td>2</td>
          <td>4</td>
          <td>6</td>
          <td>8</td>
          <td>10</td>
          <td>12</td>
        </tr>
        <tr>
          <th class="grey-class">IT</th>
          <td>34</td>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
          <td>46</td>
          <td>48</td>
        </tr>
        <tr>
          <th class="grey-class">FR</th>
          <td>30</td>
          <td>32</td>
          <td>34</td>
          <td>36</td>
          <td>38</td>
          <td>40</td>
          <td>42</td>
          <td>44</td>
        </tr>
        <tr>
          <th class="grey-class">JEANS</th>
          <td>22/23</td>
          <td>-</td>
          <td>24/25</td>
          <td>26/27</td>
          <td>28/29</td>
          <td>30/31</td>
          <td>32/33</td>
          <td>34/35</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
