<div class="tvb-dialog-container">
  <i class="material-icons" (click)="close(false)">x</i>

  <div class="tvb-dialog-body">
    <div class="tvb-dialog-inner-body">
      <div class="row">
        <div class="form-group">
          <div class="title-info">
            <div class="col-sm-12" *ngIf="title == 'Shipping costs'">
              <h2>{{ 'Perks of Direct Shipping' | translate }}</h2>
              <h4>{{ 'SAVE MONEY' | translate }}</h4>
              <span>
                {{
                  'When you buy using direct shipping, you save money on authentication fees. We check all listings before they go online, but should you end up regretting your purchase, we offer re-listing for free within the first 72 hours for items bought from both private and professional sellers.'
                    | translate
                }}
              </span>
              <br />
              <br />
              <span>
                {{
                  'If you’ve purchased from a professional seller, you’re also able to return the item within 14 days from the day you receive it, as long as the item is unused, undamaged and has the quality control label attached.'
                    | translate
                }}
              </span>
              <br />
              <br />
              <h4>{{ 'BE GOOD TO THE ENVIRONMENT' | translate }}</h4>
              <span>
                {{
                  'By using direct shipping, we avoid shipping the same item to our head office and then to you. This means the carbon footprint of your purchase will be smaller and better for the environment.'
                    | translate
                }}
              </span>
              <br />
              <br />
              <h2>{{ 'Shipping costs' | translate }}</h2>
              <h4>{{ 'NO WAITING AROUND' | translate }}</h4>
              <span>
                {{
                  'Delivery times are highly reduced as items bought with direct shipping will be shipped straight to your door and won’t have to pass through our office first. Shipping costs are calculated per item as the items can come from different sellers.'
                    | translate
                }}
              </span>
              <br />
              <br />
            </div>

            <div class="col-sm-12" *ngIf="title == 'Authentication'">
              <h2>{{ 'Authentication' | translate }}</h2>
              <h4>{{ 'VERIFICATION' | translate }}</h4>
              <span>
                {{
                  'Our quality control is conducted at our Copenhagen Office and is handled by our team of brand experts
                who verify the item’s authenticity and quality as well as condition and description.'
                    | translate
                }}
              </span>
              <br />
              <br />
              <h4>{{ 'UNWANTED SURPRISES' | translate }}</h4>
              <span>
                {{
                  'Should you receive an item you don’t believe to be in the condition that you expected, we’ll gladly
                take another look and assist in finding a solution in cooperation with the seller.'
                    | translate
                }}
              </span>
              <br />
              <br />
              <span>
                {{
                  'If we believe, the
                item is below the condition stated in the seller’s description, we’ll also assist in finding a solution
                with the seller before we ship the item to you.'
                    | translate
                }}
              </span>
              <br />
              <br />
              <br />
              <h2>{{ 'Shipping costs' | translate }}</h2>
              <h4>{{ 'NO WAITING AROUND' | translate }}</h4>
              <span>
                {{
                  'Shipping cost is calculated per item as most items are shipped separately from different sellers. We
                also ship separately from our office as we ship every item as soon as they’ve undergone quality control
                and verification in an effort to cut down on delivery times.'
                    | translate
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
