<div class="tvb-dialog-container" *ngIf="item">
  <i class="material-icons" (click)="close(false)">x</i>

  <div class="tvb-dialog-body">
    <div class="tvb-dialog-inner-body">
      <div class="row">
        <form [formGroup]="cancelSoldItemForm" #orderForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="title-info">
              <h3>Order Item Cancelation</h3>
            </div>
          </div>
          <div class="col-sm-12">
            <table class="table">
              <tr>
                <td>
                  <img class="main-image" *ngIf="item.image_url" [src]="item.image_url" [width]="100" [height]="100" />
                </td>
                <td>
                  <p>{{ item.product_name }}</p>
                  <p *ngIf="item.seller_sku">{{ item.seller_sku }}</p>
                  <p *ngIf="!item.seller_sku">{{ item.sku }}</p>
                  <p>
                    Price on site:
                    <span>{{ priceOnSite | currency: sellerPreferredCurrency }}</span>
                  </p>
                  <p>
                    Price for you:
                    <span>{{ priceForYou | currency: sellerPreferredCurrency }}</span>
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-sm-12 py-3 d-flex align-items-center">
            <hr class="flex-grow-1" />
            <hr class="flex-grow-1" />
          </div>
          <div class="form-group">
            <mat-label>{{ 'Cancelation Reason' | translate }} :</mat-label>

            <mat-select formControlName="cancelReason">
              <mat-option *ngFor="let cancelType of _cancekTypeOpts" [value]="cancelType.value" [id]="cancelType.value">
                {{ cancelType.label }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                submitted &&
                cancelSoldItemForm.controls.cancelReason.errors &&
                cancelSoldItemForm.controls.cancelReason.errors.required
              "
            >
              Please select option
            </mat-error>
          </div>
          <div class="form-group seller-item">
            <div class="col-sm-12 remove-padding">
              <mat-label>{{ 'Remove the Item from the Site?' | translate }} :</mat-label>
            </div>
            <div class="col-sm-12 remove-padding">
              <mat-card-content>
                <mat-radio-group aria-label="reStockItem" formControlName="reStockItem">
                  <mat-radio-button value="1" class="example-radio-button">Yes</mat-radio-button>
                  <mat-radio-button value="0" class="example-radio-button">No</mat-radio-button>
                </mat-radio-group>
              </mat-card-content>
            </div>
            <mat-error
              *ngIf="
                submitted &&
                cancelSoldItemForm.controls.reStockItem.errors &&
                cancelSoldItemForm.controls.reStockItem.errors.required
              "
            >
              Restock Product cannot be empty
            </mat-error>
          </div>

          <div class="col-sm-12 py-3 d-flex align-items-center">
            <hr class="flex-grow-1" />
            <hr class="flex-grow-1" />
          </div>

          <div class="col-sm-12">
            <div class="botton-title-info">Cancel Order Item</div>
            <div mat-dialog-actions>
              <button class="btn btn-secondary" style="margin-right: 10px" type="button" (click)="cancel()">NO</button>
              <button class="btn primary" type="submit">Yes</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
