import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class ScreenDetectorBootstrapService {
  private isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private mobile = false;
  private BootstrapBreakpoints: any;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.BootstrapBreakpoints = {
      XSmall: '(max-width: 575.98px)',
      Small: '(min-width: 576px)',
      Medium: '(min-width: 768px)',
      Large: '(min-width: 992px)',
      XLarge: '(min-width: 1200px)',
    };

    this.breakpointObserver
      .observe([
        this.BootstrapBreakpoints.XSmall,
        this.BootstrapBreakpoints.Small,
        this.BootstrapBreakpoints.Medium,
        this.BootstrapBreakpoints.Large,
        this.BootstrapBreakpoints.XLarge,
      ])
      .subscribe((state: BreakpointState) => {
        this.mobile = !state.breakpoints[this.BootstrapBreakpoints.Large];
        this.isMobile$.next(this.mobile);
      });
  }

  get isMobile(): BehaviorSubject<boolean> {
    return this.isMobile$;
  }
}
