import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { MetaService } from '@services/app/meta.service';
import { Response } from 'express';

@Component({
  selector: 'tvb-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  @Input() send404Response: boolean;
  constructor(
    private router: Router,
    private metaService: MetaService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {
    this.metaService.setPageNoIndex();
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      if (this.send404Response) {
        this.response.status(404);
      }
    }
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }
}
