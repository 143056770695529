<div class="container">
  <div class="not-found-container">
    <h1>SORRY, WE COULD NOT FIND THE PAGE YOU ARE LOOKING FOR.</h1>
    <p>But you might find what you are looking for in our best selections:</p>
    <div class="cta">
      <button class="btn btn-secondary" (click)="navigate('/')">Homepage</button>
      <button class="btn btn-secondary" (click)="navigate('/sale')">Sale</button>
    </div>
  </div>
  <tvb-unique-selling-point></tvb-unique-selling-point>
</div>
