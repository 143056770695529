import { APIv2SellerWrapper } from '@schemas/seller.interface';
import { APP_CONSTANTS } from '@shared/constants/app-constants';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UniversalService } from './universal.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { UserData, UserNotification } from '@schemas/user.interface';
import { AuthResponseV2, UserInfoResponse } from '@schemas/auth.interface';
import { LocalStorageService } from './local-storage.service';
import { DeliveryAddress } from '@shared/models/address.interface';
import {
  UserDetailsApiv2Address,
  UserDetailsApiv2RespModel,
} from '@shared/models/user-service-models/user-details-apiv2-resp-model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user$: BehaviorSubject<UserData | null> = new BehaviorSubject<UserData | null>(null);
  private isImpersonateSession$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoadingObs: Observable<boolean> = this.isLoading$.asObservable();
  private readonly options: { headers: HttpHeaders };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private universalService: UniversalService,
    private localStorageService: LocalStorageService
  ) {
    const access_token: string = this.localStorageService.getItem(APP_CONSTANTS.STORAGE_KEYS.ACCESS_TOKEN);

    this.options = { headers: new HttpHeaders({ Authorization: 'Bearer ' + access_token }) };
  }

  /**
   * gets UserData for given user_id
   * @param user_id
   * @returns UserInfoResponse
   */
  public getUserV2(user_id?: number): Observable<UserInfoResponse> {
    const user: UserData
      = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.USER_DATA) != ''
        ? JSON.parse(this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.USER_DATA))
        : '';

    if (user) {
      user.access_token = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.ACCESS_TOKEN);
      user.refresh_token = this.cookieService.get(APP_CONSTANTS.STORAGE_KEYS.REFRESH_KEY);
    }

    const users = [user];
    const userNotificaiton: UserNotification = { user: users };
    const userInfo: UserInfoResponse = { user: userNotificaiton };

    return of(userInfo);
  }

  public setAuthLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  get authLoading(): boolean {
    return this.isLoading$.getValue();
  }

  /**
   * Stores user data in localStorage if in broswe, and emit to user subject always
   * @param userData
   */
  public setUser(userData: UserData | null): void {
    if (this.universalService.isBrowser) {
      if (userData != null) {
        // TODO: check if cokieService works on server side, then remove it from isBrowser check
        this.storeKeys(userData);
      }
      else {
        // user = null on logout
        this.cookieDeleteAfterLogout();
        /* We use cookies for launch the functionality of consent segment manager so it should not be deleted  */
      }
    }

    this.user$.next(userData);
  }

  public getUserData(): BehaviorSubject<UserData | null> {
    return this.user$;
  }

  public getCompanyIdOrUserId(): number {
    const user: UserData = this.user$.getValue();

    return user?.tvb_company_id > 0 ? user.tvb_company_id : user?.id;
  }

  public updateGetPaid_V2(id: number, userData: UpdateGetPaidRequest): Observable<any> {
    return this.http.put(`${environment.API_V2_URL}account/get-paid`, userData);
  }

  public updateSellerOnboardingData(userData: any): Observable<any> {
    return this.http.post(`${environment.USER_SERVICE_URL}/api/user`, userData, this.options);
  }

  public getCurrentUserVerificationInformation(): Observable<any> {
    return this.http.get(`${environment.PRIVATE_SELLER_ONBOARDING_API_URL}/get-onboarding-user-data`);
  }

  public verifyPrivateSeller(userData: any): Observable<any> {
    return this.http.post(`${environment.PRIVATE_SELLER_ONBOARDING_API_URL}/verify-user`, userData);
  }

  public updateUserDetails(id: number, userData: any): Observable<any> {
    return this.http.post(`${environment.ADMIN_API_URL}/user/updatePrivateUserinfo`, userData);
  }

  public getAddressList(): Observable<any> {
    return this.http.get(`${environment.USER_SERVICE_URL}/api/user/addresses/`);
  }

  public getUserAddressByType(addressType: string): Observable<any> {
    return this.http.get(`${environment.USER_SERVICE_URL}/api/user/addresses/` + addressType);
  }

  public removeAddress(id: number): Observable<any> {
    return this.http.delete(`${environment.USER_SERVICE_URL}/api/addresses/` + id, this.options);
  }

  public setDefaultAddress(id: number): Observable<any> {
    return this.http.put(`${environment.USER_SERVICE_URL}/api/addresses/setDefault/` + id, this.options);
  }

  public getDeliveryAddress(id: number): Observable<UserDetailsApiv2Address> {
    return this.http.get<UserDetailsApiv2Address>(`${environment.USER_SERVICE_URL}/api/addresses/` + id);
  }

  public updateAddress(id: number, addressData): Observable<any> {
    addressData.id = id;

    return this.http.put(`${environment.USER_SERVICE_URL}/api/addresses/` + id, { ...addressData }, this.options);
  }

  public partialUpdateAddress(id: number, addressData): Observable<any> {
    addressData.id = id;

    return this.http.patch(`${environment.USER_SERVICE_URL}/api/addresses/` + id, { ...addressData }, this.options);
  }

  public getallCountryPhoneCode(): Observable<any> {
    return this.http.get(`${environment.USER_API_URL}/getallCountryPhoneCode`);
  }

  public verifyAddress(addressData): Observable<any> {
    return this.http.post(`${environment.API_V2_URL}delivery-address/verify`, addressData);
  }

  public addDeliveryAddress(addressData): Observable<any> {
    return this.http.post(`${environment.USER_SERVICE_URL}/api/addresses/`, addressData, this.options);
  }

  public addBillingAddress(userId: number, requestPayload: BillingAddressRequestModel): Observable<any> {
    return this.http.post(`${environment.USER_SERVICE_URL}/api/addresses/client/billing`, requestPayload, this.options);
  }

  public updateBillingAddress(userId: number, requestPayload: BillingAddressRequestModel): Observable<any> {
    return this.http.put(`${environment.USER_SERVICE_URL}/api/addresses/client/billing`, requestPayload, this.options);
  }

  public newsletterSubscribe(newsletterData): Observable<any> {
    return this.http.post(`${environment.API_V2_URL}auth/sendgridAddContactNewsletter`, newsletterData);
  }

  public softRegister(data): Observable<any> {
    return this.http.post(`${environment.API_V2_URL}auth/soft_register`, data);
  }

  public forgotPassword(userdata): Observable<any> {
    return this.http.post(`${environment.API_V2_URL}password-recovery/getRecoveryCode`, userdata);
  }
  public changePassword(userdata): Observable<any> {
    return this.http.post(`${environment.API_V2_URL}password-recovery/changePassword`, userdata);
  }

  public updateUserInfo(id: number, userData: any): Observable<any> {
    return this.http.post(`${environment.USER_SERVICE_URL}/api/user-profiles/user/${id}`, userData, this.options);
  }

  public getUserDeliveryAddress(userId: number): Observable<any> {
    return this.http.get(`${environment.USER_SERVICE_URL}/api/user/addresses/shipping/`, this.options);
  }

  public getUserDetailsFromApiv2(userId: number): Observable<UserDetailsApiv2RespModel> {
    return this.http.get<UserDetailsApiv2RespModel>(
      `${environment.USER_SERVICE_URL}/api/users/id/apiv2/details/${userId}`,
      this.options
    );
  }

  //TODO: Check this method. Check also the endpoint in admin_cms.
  public getAccessToken(userData: UserData): Observable<any> {
    this.storeKeys(userData);

    return of(this.localStorageService.getItem(APP_CONSTANTS.STORAGE_KEYS.ACCESS_TOKEN));
  }

  public getAccessTokenV2(userData: UserData): string {
    return this.localStorageService.getItem(APP_CONSTANTS.STORAGE_KEYS.ACCESS_TOKEN);
  }

  public getSeller(user_id: number): Observable<APIv2SellerWrapper> {
    return this.http.get<APIv2SellerWrapper>(`${environment.API_V2_URL}user/${user_id}`);
  }

  public impersonateAdmin(uuid: string, access_token: string): Observable<any> {
    const options = { headers: new HttpHeaders({ Authorization: 'Bearer ' + access_token }) };

    return this.http.put(`${environment.USER_SERVICE_URL}/api/admin/users/impersonate/` + uuid, null, options);
  }

  public getIsImpersonateSession() {
    return this.isImpersonateSession$;
  }

  public setIsImpersonateSession(value: boolean) {
    if (value) {
      this.isImpersonateSession$.next(true);
    }
    else {
      this.isImpersonateSession$.next(false);
    }
  }

  private storeKeys(userData: UserData): void {
    if (userData.access_token) {
      const ignoredKeys = ['access_token', 'refresh_token'];

      this.cookieService.set(
        APP_CONSTANTS.COOKIE_KEYS.USER_DATA,
        JSON.stringify(userData, (key, value) => (ignoredKeys.includes(key) ? undefined : value)),
        {
          path: '/',
          domain: environment.cookieDomain,
        }
      );
      this.cookieService.set(APP_CONSTANTS.COOKIE_KEYS.USER_ID, userData.id + '', {
        path: '/',
        domain: environment.cookieDomain,
      });

      this.cookieService.set(APP_CONSTANTS.COOKIE_KEYS.ACCESS_TOKEN, userData.access_token, {
        path: '/',
        domain: environment.cookieDomain,
      });

      localStorage.setItem(APP_CONSTANTS.COOKIE_KEYS.ACCESS_TOKEN, userData.access_token);
      localStorage.setItem(APP_CONSTANTS.COOKIE_KEYS.API_KEY, userData.access_token);
    }

    if (userData.refresh_token) {
      localStorage.setItem(APP_CONSTANTS.STORAGE_KEYS.REFRESH_KEY, userData.refresh_token);
      this.cookieService.set(APP_CONSTANTS.COOKIE_KEYS.REFRESH_TOKEN, userData.refresh_token, {
        path: '/',
        domain: environment.cookieDomain,
      });
    }

    if (userData?.user?.refresh_token) {
      localStorage.setItem(APP_CONSTANTS.STORAGE_KEYS.REFRESH_KEY, userData.user.refresh_token);
    }
  }

  public async refreshUser(userId: number) {
    this.setAuthLoading(true);
    const refreshToken = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.REFRESH_TOKEN);

    if (refreshToken) {
      this.refresh(refreshToken).subscribe({
        next: (response: AuthResponseV2) => {
          response.user.refresh_token = response.refresh_token;
          response.user.access_token = response.access_token;
          this.setUser(response.user);
          this.setAuthLoading(false);
        },
        error: () => {
          this.setUser(null);
          this.clearLocalStorageAndLeaveAdminIfExists();
          this.setAuthLoading(false);
        },
      });
      const freshUser = (await firstValueFrom(this.getUserV2(userId))).user.user[0];

      this.setUser(freshUser);
    }
    else {
      this.setUser(null);
      this.setAuthLoading(false);
    }
  }

  public refresh(refresh_token: string): Observable<AuthResponseV2> {
    return this.http.post<AuthResponseV2>(
      `${environment.USER_SERVICE_URL}/api/public/refresh`,
      { refresh_token: refresh_token ? refresh_token : localStorage.getItem(APP_CONSTANTS.STORAGE_KEYS.REFRESH_KEY) },
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      }
    );
  }

  private cookieDeleteAfterLogout() {
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.DEVICE_ID, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.USER_DATA, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CART_COUNT, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.ACCESS_TOKEN, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.REFRESH_TOKEN, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.API_KEY, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.USER_ID, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CLIENT_WEBSITE_TOKEN, '/', environment.cookieDomain);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CONFIG_ID, '/', environment.cookieDomain);

    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CART_COUNT);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.DEVICE_ID);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CLIENT_WEBSITE_TOKEN);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CART_COUNT);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.ACCESS_TOKEN);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.REFRESH_TOKEN);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.API_KEY);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.USER_ID);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CLIENT_WEBSITE_TOKEN);
    this.cookieService.delete(APP_CONSTANTS.COOKIE_KEYS.CONFIG_ID);
    this.cookieService.delete('ajs_user_id');
    this.cookieService.delete('items', '/', environment.cookieDomain);
  }

  public clearLocalStorageAndLeaveAdminIfExists() {
    const adminData = this.localStorageService.getItem(APP_CONSTANTS.STORAGE_KEYS.ADMIN);

    this.localStorageService.clear();
    this.localStorageService.setItem(APP_CONSTANTS.STORAGE_KEYS.ADMIN, adminData);
  }

  public mapShippingAddressResponseModelToBillingAddressRequestModel(
    shippingAddress: AddOrUpdateShippingAddressResponseModel
  ): BillingAddressRequestModel {
    const addOrUpdateBillingAddressRequestModel: BillingAddressRequestModel = {
      ...shippingAddress,
      pin_code: shippingAddress.pin_code,
      country: shippingAddress.country_id,
      address_1: shippingAddress.line1,
      address_2: shippingAddress.line2,
    };

    delete addOrUpdateBillingAddressRequestModel['line1'];
    delete addOrUpdateBillingAddressRequestModel['line2'];
    delete addOrUpdateBillingAddressRequestModel['country_name'];
    delete addOrUpdateBillingAddressRequestModel['country_name'];
    delete addOrUpdateBillingAddressRequestModel['country_sortname'];
    delete addOrUpdateBillingAddressRequestModel['default_address'];
    delete addOrUpdateBillingAddressRequestModel['isDeleted'];
    delete addOrUpdateBillingAddressRequestModel['postalCode'];

    return addOrUpdateBillingAddressRequestModel;
  }

  public mapUserDetailApiv2AddressToDeliveryAddress(userDetailAddress: UserDetailsApiv2Address): DeliveryAddress {
    const mappedAddress: DeliveryAddress = {
      address_1: userDetailAddress.line1,
      address_2: userDetailAddress.line2,
      address_type: userDetailAddress.type,
      alternate_no: null,
      city: userDetailAddress.city,
      company: userDetailAddress.company,
      country_id: userDetailAddress.country_id,
      country_name: userDetailAddress.country_name,
      country_sortname: userDetailAddress.country_sortname,
      default_address: userDetailAddress.default_address,
      fname: userDetailAddress.fname,
      id: userDetailAddress.id,
      landmark: null,
      lname: userDetailAddress.lname,
      mobile_no: userDetailAddress.mobile_no,
      name: null,
      phone_code: userDetailAddress.phonecode,
      pin_code: userDetailAddress.pin_code,
      state: userDetailAddress.state,
    };

    return mappedAddress;
  }
}

export interface UpdateGetPaidRequest {
  id?: number;
  role_id?: number;
  connected_stripe_id?: string;
  username?: string;
  email?: string;
  slpassword?: null;
  phone_code?: string;
  mobile_no?: string;
  first_name?: string;
  last_name?: string;
  dob?: Date;
  gender?: string;
  address?: string;
  address_2?: string;
  country_id?: number;
  city_id?: null;
  state_id?: null;
  news_letter?: string;
  image_id?: string;
  language_id?: number;
  currency_id?: number;
  status?: string;
  loyality_programme?: string;
  api_key?: string;
  access_token?: string;
  social_auth_id?: null;
  preferred_currency?: number;
  vat_number?: string;
  company_name?: string;
  postal_code?: string;
  login_type?: string;
  shipping_timing?: string;
  pin_code?: null;
  city?: string;
  state?: string;
  company?: null;
  catalog_type?: string;
  catalog_url?: string;
  shipped_from?: number;
  bank_country?: number;
  bank_account_number?: string;
  soft_registered?: number;
  enable_sell?: string;
  updated_at?: Date;
  created_at?: Date;
  cover_image?: string;
  member_description?: string;
  product_count?: number;
  fname?: string;
  lname?: string;
  date_of_birth?: Date;
}

export interface AddOrUpdateShippingAddressResponseModel {
  city: string;
  company: null;
  country: {
    id: number;
    name: string;
    code: string;
  };
  country_id: number;
  country_name: string;
  country_sortname: string;
  default_address: 'yes' | 'no';
  fname: string;
  id: number;
  isDeleted: number;
  line1: string;
  line2: string;
  lname: string;
  mobile_no: string;
  phonecode: string;
  pin_code: string;
  postalCode: string;
  state: string;
  type: string;
  userId: string;
}

export interface BillingAddressRequestModel {
  address_1: string;
  address_2: string;
  city: string;
  company?: string;
  country: number;
  fname: string;
  id?: number;
  lname: string;
  mobile_no: string;
  phonecode: string;
  pin_code: string;
  postalCode: string;
  state: string;
  userId: string;
}
