<div class="footer">
  <div class="container">
    <div class="row">
      <div class="footer-item col-lg-4 col-xs-12 col-sm-12">
        <div class="footer-item-icon">
          <img src="/assets/images/icons/refresh.svg" class="footer-item-icon-image" />
        </div>
        <div class="footer-item-title">WE BELIEVE IN SECOND CHANCE</div>
        <div class="footer-item-description">Selling what you no longer wear makes you the hero of our love story</div>
      </div>

      <div class="footer-item col-lg-4 col-xs-12 col-sm-12">
        <div class="footer-item-icon">
          <img src="/assets/images/icons/chat.svg" class="footer-item-icon-image" />
        </div>
        <div class="footer-item-title">JOIN OUR COMMUNITY</div>
        <div class="footer-item-description">
          Sell to +200.000 members from around the world who share your passion for secondhand fashion
        </div>
      </div>

      <div class="footer-item col-lg-4 col-xs-12 col-sm-12">
        <div class="footer-item-icon">
          <img src="/assets/images/icons/bag.svg" class="footer-item-icon-image perfect-match" />
        </div>
        <div class="footer-item-title">THE PERFECT MATCH</div>
        <div class="footer-item-description">Name a better duo than fashion and making money, we'll wait</div>
      </div>
    </div>
  </div>
</div>

<div class="checkoutpolicies">
  <div class="container text-center">
    <div class="row">
      <ul class="checkoutpoliciesul">
        <li class="checkoutpoliciesli">
          <a class="checkoutpoliciesla">© {{ currentDate }} The Vintage Bar</a>
        </li>
        <li class="checkoutpoliciesli">
          <a class="checkoutpoliciesla" routerLink="/{{ 'privacy-policy' | translate }}">
            {{ 'Privacy & Security' | translate }}
          </a>
        </li>
        <li class="checkoutpoliciesli">
          <a class="checkoutpoliciesla" routerLink="/{{ 'returns' | translate }}">
            {{ 'Return Policy' | translate }}
          </a>
        </li>
        <li class="checkoutpoliciesli">
          <a class="checkoutpoliciesla" routerLink="/{{ 'terms-and-conditions' | translate }}">
            {{ 'Terms' | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
