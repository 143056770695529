<div class="main d-flex flex-column">
  <div class="header-container">
    <app-header></app-header>
  </div>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>

  <div class="footer-container">
    <app-footer></app-footer>
  </div>
</div>
