import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccountService } from '@services/account.service';
import { AuthService } from '@services/auth.service';
import { UserService } from '@services/user.service';
import { APP_CONSTANTS } from '@shared/constants/app-constants';
import { TermsEnum } from '@shared/enums/terms.enum';
import { UserRolesEnum } from '@shared/enums/user-roles.enum';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class SellersAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private cookieService: CookieService,
    private accountService: AccountService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userId = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.USER_ID);

    if (userId) {
      const user = this.userService.getUserData().getValue();

      if (user?.role_id == UserRolesEnum.PROFESSIONAL_SELLER) {
        this.accountService.getAcceptTerms(user?.id, TermsEnum.PRO_SELLERS_TERMS).subscribe(
          res => {
            if (!res.body.accepted) {
              this.router.navigate(['/account/terms-and-conditions/accept-terms']);

              return false;
            }

            return true;
          },
          () => {
            this.router.navigate(['/account/terms-and-conditions/accept-terms']);

            return false;
          }
        );
        this.accountService.getAcceptTerms(user?.id, TermsEnum.MY_AGREEMENTS).subscribe(
          res => {
            if (!res.body.accepted) {
              this.router.navigate(['/account/terms-and-conditions/my-agreements']);

              return false;
            }

            return true;
          },
          () => {
            this.router.navigate(['/account/terms-and-conditions/my-agreements']);

            return false;
          }
        );
      }

      return true;
    }
    else {
      this.router.navigate(['/sellers/login'], { queryParams: { returnUrl: state.url } });

      return false;
    }
  }
}
