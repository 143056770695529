import { Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { UserService } from './core/services/user.service';
import { AuthService } from './core/services/auth.service';
import { environment } from '@environments/environment';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthResponseV2, UserInfoResponse } from '@schemas/auth.interface';
import { AppService } from '@services/app/app.service';
import { AppConfiguration, Currency } from '@schemas/app.interface';
import { UserData } from '@schemas/user.interface';
import { LocalStorageService } from '@services/local-storage.service';
import { APP_CONSTANTS } from '@shared/constants/app-constants';
import { UniversalService } from '@services/universal.service';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { MenuService } from '@services/common/menu.service';
import { UrlService } from '@services/url.service';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '@services/account.service';
import { firstValueFrom, timer } from 'rxjs';
import { LanguageEnum } from '@shared/enums/language.enum';
import { CatalogCategoryLevelEnum } from '@shared/enums/catalog-category-level.enum';
import { ProductService } from '@services/product.service';
import GetShoppingCartRequestFiltered from '@services/cart/models/get-shopping-cart-request-filtered';
import { CartService } from '@services/cart/cart.service';
import { SharedService } from '@services/common/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'angular-website';

  public previousUrl = '';
  public currentUrl = '';
  public previousPDPUrl = '';
  private availableCurrencies: Currency[];
  private previousSubdomain: string | 'de' | 'dk' | 'se' | 'uk' | 'fr' | 'es' | 'it';
  private currentSubdomain: string | 'de' | 'dk' | 'se' | 'uk' | 'fr' | 'es' | 'it';
  public AuthenticationServicesFee = 'Authentication-Services-Fee';

  constructor(
    public translate: TranslateService,
    public localStorageService: LocalStorageService,
    private userService: UserService,
    private authService: AuthService,
    private accountService: AccountService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private universalService: UniversalService,
    private cookieService: CookieService,
    private urlService: UrlService,
    @Inject(DOCUMENT) private doc: Document,
    private menuService: MenuService,
    private renderer2: Renderer2,
    public productService: ProductService,
    private cartService: CartService,
    private sharedService: SharedService
  ) {
    // TODO:Ayham  load language from the user's settings
    translate.addLangs(['en', 'de', 'dk', 'se', 'uk', 'fr', 'es', 'it']);
    translate.setDefaultLang('en');
  }

  async ngOnInit(): Promise<void> {
    this.availableCurrencies = await firstValueFrom(this.accountService.getCurrencyList());
    this.clearDataForUserService();
    this.setupConfigurationSettings();
    this.getSaleBadgePercentageActive();
    this.getAuthenticationServicesFee();

    if (this.universalService.isBrowser) {
      this.setTiktok();
      this.setAwin();
      this.restoreSession();
      this.loadGoogleOptimize();
    }
    else {
      // on server rendering, user is always not authinticated
      this.userService.setUser(null);
      this.authService.setLoggedIn(false);
    }

    if (this.universalService.isBrowser && environment.production) {
      this.setSleekNote();
    }

    if (this.universalService.isBrowser) {
      this.getUserDataFromLocalStorage();
    }

    if (this.universalService.isBrowser && !environment.production) {
      document
        .querySelector('meta[name="google-site-verification"]')
        .setAttribute('content', '9m3WaAsUzCoyEKZseXHbeeAIUGA7GWuQP3absS6M8xM');
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
      this.previousUrl = this.currentUrl;

      if (event.url.includes('pdp=')) {
        this.previousPDPUrl = event.url;
      }

      this.urlService.setPreviousUrl(this.previousUrl);
      this.urlService.setPreviousPDPUrl(this.previousPDPUrl);
    });

    this.appService.getAppConfigurationBehavior().subscribe((freshAppConfig: AppConfiguration) => {
      this.appService
        .loadCatalogCategories$(freshAppConfig.languageShortName as LanguageEnum, CatalogCategoryLevelEnum.PARENT)
        .subscribe(val => this.appService.updateCatalogParentCategories$(val));
    });
  }

  clearDataForUserService() {
    if (!this.cookieService.get('user_service')) {
      this.cookieService.deleteAll();
      this.userService.clearLocalStorageAndLeaveAdminIfExists();
      this.cookieService.set('user_service', '1');
    }
  }

  private getUserDataFromLocalStorage(): void {
    const lsUserGuid = this.localStorageService.getItem('user_guid');
    const coUserGuid = this.cookieService.get('user_guid');

    if (!lsUserGuid && coUserGuid) {
      this.localStorageService.setItem('user_guid', coUserGuid);
    }
    else if (!lsUserGuid && !coUserGuid) {
      const guid = this.newGuid();

      this.localStorageService.setItem('user_guid', guid);

      this.cookieService.set('user_guid', guid, {
        path: '/',
        domain: environment.cookieDomain,
      });
    }
  }

  public newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;

      return v.toString(16);
    });
  }

  /**
   * To check localStorage data on app load,
   * if exists , calls get loggeduserInfo API to update the saved data
   * Should be called only in a browser for universal to work
   */
  public restoreSession(): void {
    this.userService.setAuthLoading(true);

    const refreshUser = (refreshToken: string) => {
      if (refreshToken) {
        this.userService.refresh(refreshToken).subscribe({
          next: (response: AuthResponseV2) => {
            this.authService.setLoggedIn(true);
            response.user.refresh_token = response.refresh_token;
            response.user.access_token = response.access_token;
            this.userService.setUser(response.user);
            this.userService.setAuthLoading(false);
            this.getWishlistedProducts();
          },
          error: () => {
            this.userService.setUser(null);
            this.authService.setLoggedIn(false);
            this.userService.setAuthLoading(false);
            this.userService.clearLocalStorageAndLeaveAdminIfExists();
          },
        });
      }
      else {
        this.userService.setUser(null);
        this.authService.setLoggedIn(false);
        this.userService.setAuthLoading(false);
        this.userService.clearLocalStorageAndLeaveAdminIfExists();
      }
    };

    const restoreSessionFromCookiesOrUrl = () => {
      // Setup User Auth Variables
      const userAccessTokenFromCookie = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.ACCESS_TOKEN);
      const refreshTokenFromCookie = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.REFRESH_TOKEN);
      const localUserData: string | UserData
        = this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.USER_DATA) != ''
          ? JSON.parse(this.cookieService.get(APP_CONSTANTS.COOKIE_KEYS.USER_DATA))
          : '';

      if (localUserData && typeof localUserData === 'object') {
        localUserData.access_token = userAccessTokenFromCookie;
        localUserData.refresh_token = refreshTokenFromCookie;
      }

      // Restore Session From Cookies
      if (refreshTokenFromCookie) {
        refreshUser(refreshTokenFromCookie);
      }
      else {
        // Restore Session From Impersonations
        this.userService.getIsImpersonateSession().subscribe(isImpersonation => {
          if (isImpersonation) {
            const impersonationTimer = timer(APP_CONSTANTS.IMPERSONATION_SESSION_DURATION);

            console.log('Start Impersonation Session');
            impersonationTimer.subscribe(finished => {
              console.log('End Impersonation Session');
              this.userService.setUser(null);
              this.authService.setLoggedIn(false);
              this.userService.setIsImpersonateSession(false);
              this.userService.setAuthLoading(false);
              window.location.href = this.universalService.getApplicationProtocol() + environment.demainUrl;
            });
          }
          else {
            // Refresh Or Terminate Session if refresh token is an empty string
            refreshUser(refreshTokenFromCookie);
          }
        });
      }
    };

    // On first app load : check if there is logged in user in cookie || storage
    this.userService.getUserV2().subscribe({
      next: (userDataResponse: UserInfoResponse) => {
        const user: UserData = userDataResponse.user.user[0];

        if (user && user['access_token']) {
          this.userService.setUser(user);
          this.userService.refresh(user.access_token).subscribe({
            next: () => {
              this.authService.setLoggedIn(true);
              this.userService.setAuthLoading(false);
              this.getWishlistedProducts();
            },
            error: error => {
              restoreSessionFromCookiesOrUrl();
            },
          });
        }
        else {
          restoreSessionFromCookiesOrUrl();
        }

        this.spinnerService.hide();
      },
      error: error => {
        restoreSessionFromCookiesOrUrl();
        this.spinnerService.hide();
      },
    });
  }

  getWishlistedProducts() {
    this.productService.getWishlist().subscribe(
      data => {
        this.productService.updateUserWishlistRecords(data);
      },
      error => {
        console.log(error);
      }
    );
  }

  getSaleBadgePercentageActive() {
    this.appService.getAppSetting('sale-badge').subscribe(
      (data: any) => {
        if (data.message === 'success') {
          if (data.model.status == 'active') this.appService.setSaleBadgePercentageEnable(true);
          else this.appService.setSaleBadgePercentageEnable(false);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  updateCart(device_id: string, access_token: string) {
    const appConfig: AppConfiguration = this.appService.getAppConfigurationValue();
    const cartRequest: GetShoppingCartRequestFiltered = {
      device_id: device_id,
      language: appConfig.languageShortName,
      currency: appConfig.currencyCode,
    };

    this.cartService.getCart(cartRequest, access_token).subscribe(response => {
      if (response?.cart && response?.products) {
        this.localStorageService.setItem(APP_CONSTANTS.STORAGE_KEYS.CART, JSON.stringify(response.cart));
        this.localStorageService.setItem(APP_CONSTANTS.STORAGE_KEYS.PRODUCTS, JSON.stringify(response.products));
        this.sharedService.updateItemCount(response.products.length);
        this.sharedService.updateCartCount(response.products.length);
      }
    });
  }

  // Delete
  setUserFromCookie(apiKey: string, userId: number, localAcessToken: string) {
    const tempLocalUserData: UserData = {
      status: 'success',
      api_key: apiKey,
      id: userId,
      access_token: localAcessToken,
      refresh_token: localAcessToken,
    };
  }

  getSessionFromAPI(userId: number) {
    this.spinnerService.show();

    this.userService.getUserV2(userId).subscribe({
      next: (userDataResponse: UserInfoResponse) => {
        const user: UserData = userDataResponse.user.user[0];

        this.userService.setUser(user);
        this.userService.refresh(user.access_token).subscribe({
          next: () => {
            this.authService.setLoggedIn(true);
            this.getWishlistedProducts();
          },
          error: () => {
            this.authService.setLoggedIn(false);
          },
        });
        this.spinnerService.hide();
      },
      error: () => {
        this.userService.setUser(null);
        this.authService.setLoggedIn(false);
        this.spinnerService.hide();
      },
    });
  }

  public setupConfigurationSettings(): void {
    const currentAppConfiguaration = this.appService.getAppConfigurationValue();

    this.currentSubdomain = this.appService.getCurrentSubDomain(null);

    if (currentAppConfiguaration == null) {
      // load for first time, no localStorage configuration
      // check if the cookies has a configurationId, then get these configuration ,
      // otherwise,  Get default configuration from API, and set it in appConfiguration
      this.getDefaultConfiguration();
    }
    else {
      // Already have local configuration , check if language needs to be changed based on the subdomain
      // if yes, update the language only in AppConfig
      const subdomain = this.appService.getCurrentSubDomain(null);

      this.setupCurrencyConfig(this.appService.getLangIdOfSubDomain(subdomain), currentAppConfiguaration.languageID);
    }
  }

  getDefaultConfiguration() {
    this.setFallBackDefaultConfig();
  }

  public setSleekNote() {
    const s = this.doc.createElement('script');

    s.type = 'text/javascript';
    s.id = 'sleeknoteScript';
    s.innerHTML
      = '(function () {var sleeknoteScriptTag = document.createElement("script"); sleeknoteScriptTag.type = "text/javascript"; sleeknoteScriptTag.charset = "utf-8"; sleeknoteScriptTag.src = ("//sleeknotecustomerscripts.sleeknote.com/43267.js"); var s = document.getElementById("sleeknoteScript");s.parentNode.insertBefore(sleeknoteScriptTag, s); })();';
    const head = this.doc.getElementsByTagName('head')[0];

    head.appendChild(s);
  }

  public setTiktok() {
    const s = this.doc.createElement('script');

    s.type = 'text/javascript';
    s.id = 'tiktok';
    s.innerHTML
      = '!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load("'
      + environment.TIKTOK_ID
      + '"); ttq.page(); }(window, document, "ttq")';
    const head = this.doc.getElementsByTagName('head')[0];

    head.appendChild(s);
  }

  public loadGoogleOptimize() {
    const s = this.doc.createElement('script');

    s.type = 'text/javascript';
    s.src = s.src = 'https://www.googleoptimize.com/optimize.js?id=' + environment.GOOGLE_OPTIMIZE_ID;
    const head = this.doc.getElementsByTagName('head')[0];

    head.appendChild(s);
  }

  public setAwin() {
    const subDomain = this.appService.getCurrentSubDomain(null);

    if (subDomain === 'dk' || subDomain === 'se' || subDomain === 'de') {
      const script = this.doc.createElement('script');

      script.type = 'text/javascript';
      script.defer = true;
      script.id = 'dwinScript';

      if (subDomain === 'dk') script.src = `https://www.dwin1.com/${environment.AWIN_MERCHANT_ID.DK}.js`;

      if (subDomain === 'se') script.src = `https://www.dwin1.com/${environment.AWIN_MERCHANT_ID.SE}.js`;

      if (subDomain === 'de') script.src = `https://www.dwin1.com/${environment.AWIN_MERCHANT_ID.DE}.js`;

      this.renderer2.appendChild(this.doc.body, script);
    }
  }

  private setFallBackDefaultConfig() {
    const subdomain = this.appService.getCurrentSubDomain(null);
    const langOfSubDomain = this.appService.getLangOfSubDomain(subdomain);
    const appConfig = {
      currencyID: 11,
      currencyCode: 'EUR',
      currencySymbol: '€',
      languageID: langOfSubDomain.languageID,
      languageName: langOfSubDomain.languageName,
      languageShortName: langOfSubDomain.languageShortName,
      countryID: 1,
      countryName: 'United States',
      countrycode: 'US',
      configuration: 1,
    } as AppConfiguration;

    this.appService.setAppConfiguration(appConfig);
    this.requestMenuList(appConfig.languageID);
  }

  private checkAndSetConfig(appConfig: AppConfiguration): void {
    const subdomain = this.appService.getCurrentSubDomain(null);
    const langOfSubDomain = this.appService.getLangOfSubDomain(subdomain);

    if (langOfSubDomain.languageID !== appConfig.languageID) {
      // update local configuration to correct language
      appConfig.languageID = langOfSubDomain.languageID;
      appConfig.languageName = langOfSubDomain.languageName;
      appConfig.languageShortName = langOfSubDomain.languageShortName;
    }

    this.appService.setAppConfiguration(appConfig);
    this.requestMenuList(appConfig.languageID);
  }

  /**
   * The main and only function to request the menu lists for nav, sidenav and footer.
   * @param languageId
   */
  public requestMenuList(languageId): void {
    this.menuService.getMenu(languageId).subscribe((data: any) => {});
    this.menuService.getMenuFooter(languageId).subscribe((data: any) => {});
  }

  private setupCurrencyConfig(currentLanguagueId, previousLanguageId): void {
    const currentSubDomain = !this.currentSubdomain || this.currentSubdomain === '' ? 'en' : this.currentSubdomain;

    if (this.activatedRoute.snapshot.queryParams['pdp'] && this.activatedRoute.snapshot.queryParams['keepCurrency']) {
      const currencyCodeFromUrl: string = this.activatedRoute.snapshot.queryParams['keepCurrency'];

      this.setCurrencyIntoAppConfig(currencyCodeFromUrl);
    }
    else if (!this.authService.loggedIn && currentLanguagueId !== previousLanguageId) {
      const currency = this.getCurrencyAccordingToSubdomain(currentSubDomain);
      const language = this.getLanguageAccordingToSubdomain(currentSubDomain);
      const country = this.getCountryAccordingToSubdomain(currentSubDomain);

      this.setCurrencyIntoAppConfig(currency.code, language, country);
    }

    this.appService.useLanguage(currentSubDomain);
    this.requestMenuList(currentLanguagueId);
  }

  getLanguageAccordingToSubdomain(currentSubdomain: string) {
    return this.appService.languages.find(x => x.shortName === currentSubdomain);
  }

  getCountryAccordingToSubdomain(currentSubdomain: string) {
    return this.appService.countries.find(x => x.shortName === currentSubdomain);
  }

  private getCurrencyAccordingToSubdomain(subdomain: string): Currency {
    let currency: Currency = this.availableCurrencies.find(_ => _.code === 'EUR');

    if (subdomain === 'uk') {
      currency = this.availableCurrencies.find(_ => _.code === 'GBP');
    }
    else if (subdomain === 'dk') {
      currency = this.availableCurrencies.find(_ => _.code === 'DKK');
    }
    else if (subdomain === 'se') {
      currency = this.availableCurrencies.find(_ => _.code === 'SEK');
    }

    return currency;
  }

  public setCurrencyIntoAppConfig(currencyCode = 'EUR', language?, country?): void {
    const currency: Currency = this.availableCurrencies.find(_ => _.code === currencyCode);
    const oldAppConfig: AppConfiguration = this.appService.getAppConfigurationValue();
    let newAppConfig: AppConfiguration;

    if (language && country) {
      newAppConfig = {
        ...oldAppConfig,
        currencyID: currency.id,
        currencyCode: currency.code,
        currencySymbol: currency.symbol,
        languageID: language.id,
        languageName: language.name,
        languageShortName: language.shortName,
        countryID: country.id,
        countryName: country.name,
      };
    }
    else {
      newAppConfig = {
        ...oldAppConfig,
        currencyID: currency.id,
        currencyCode: currency.code,
        currencySymbol: currency.symbol,
      };
    }

    this.appService.setAppConfiguration(newAppConfig);
  }

  getAuthenticationServicesFee(): void {
    this.appService.getAppSetting(this.AuthenticationServicesFee).subscribe(
      (data: any) => {
        if (data.message === 'success') {
          if (data.model.status == 'active') this.appService.setAuthenticationServicesFee(JSON.parse(data.model.value));
          else this.appService.setAuthenticationServicesFee({});
        }
      },
      error => {
        console.log(error);
      }
    );
  }
}
