<div class="ps-table">
  <h2>{{ 'Size Converter' | translate }}</h2>
  <i class="material-icons" (click)="onClose(false)">clear</i>
  <p>{{ 'The following charts will help you match the size for this product to your usual size.' | translate }}</p>
  <div class="table-body table-bordered">
    <table class="table">
      <thead>
        <tr>
          <th>UK</th>
          <th>US</th>
          <th>IT</th>
          <th>FR</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>3</td>
          <td>6</td>
          <td>36</td>
          <td>37</td>
        </tr>
        <tr>
          <td>3.5</td>
          <td>6.5</td>
          <td>36.5</td>
          <td>37.5</td>
        </tr>
        <tr>
          <td>4</td>
          <td>7</td>
          <td>37</td>
          <td>38</td>
        </tr>
        <tr>
          <td>4.5</td>
          <td>7.5</td>
          <td>37.5</td>
          <td>38.5</td>
        </tr>
        <tr>
          <td>5</td>
          <td>8</td>
          <td>38</td>
          <td>39</td>
        </tr>
        <tr>
          <td>5.5</td>
          <td>8.5</td>
          <td>38.5</td>
          <td>39.5</td>
        </tr>
        <tr>
          <td>6</td>
          <td>9</td>
          <td>39</td>
          <td>40</td>
        </tr>
        <tr>
          <td>6.5</td>
          <td>9.5</td>
          <td>39.5</td>
          <td>40.5</td>
        </tr>
        <tr>
          <td>7</td>
          <td>10</td>
          <td>40</td>
          <td>41</td>
        </tr>
        <tr>
          <td>7.5</td>
          <td>10.5</td>
          <td>40.5</td>
          <td>41.5</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
