export enum UserRolesEnum {
  TVB_ADMIN = 0,
  USER = 1,
  INFLUENCER = 24,
  PROFESSIONAL_SELLER = 15,
}

export enum UserRolesStringEnum {
  TVB_ADMIN = 'admin',
  USER = 'user',
  INFLUENCER = 'influencer',
  PROFESSIONAL_SELLER = 'professional',
}
